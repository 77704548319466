/* eslint-disable jsx-a11y/anchor-has-content */
import { useMemo, useState, useRef, useEffect, useCallback } from 'react'
import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation, Link } from 'server/i18n'
import Swiper from 'react-id-swiper'
import { useUIDSeed } from 'react-uid'
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom-ssr'
import { Box } from 'reflexbox'
import Button from 'components/Button'
import Modal from 'components/Modal'

import layoutTheme from 'theme/layout-theme'

import style from './PhotoGallerySlider.style'

const PhotoGallerySliderStyled = styled.div(props => ({ ...style(props) }))

const PhotoGallerySlider = ({
    t,
    images,
    gtmPDPInterior,
    gtmPDPExterior,
    specialOffers,
    featuredYachts,
    featuredButton
}) => {
    const [modalImageUrl, setModalImageUrl] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [showMoreOffers, setShowMoreOffers] = useState(false)
    const [largeOffset, setLargeOffset] = useState(0)

    const uniqueImages = useMemo(() => {
        return (images || []).reduce((all, image) => {
            if (!all.find(item => item.url === image.url)) {
                all.push(image)
            }
            return all
        }, [])
    }, [images])

    const imgRef = useRef()

    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef

        if (img) {
            const value = make3dTransformValue({ x, y, scale })

            img.style.setProperty('transform', value)
        }
    }, [])

    const openImageModal = imageUrl => {
        setModalImageUrl(imageUrl)
        setModalOpen(true)
    }

    const handleShowMoreOffers = () => {
        setShowMoreOffers(true)
    }

    const isBrowser = typeof window !== 'undefined'

    useEffect(() => {
        setLargeOffset(process.browser && (window.innerWidth - 1600) / 2)
    }, [])

    const params = {
        slidesPerView: 'auto',
        spaceBetween: 0,
        mousewheel: {
            forceToAxis: true,
            invert: true
        },
        releaseOnEdges: true,
        freeMode: true,
        simulateTouch: true,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
            dragSize: 60,
            snapOnRelease: false,
            slidesOffsetAfter: 40
        },
        breakpoints: {
            1024: {
                slidesOffsetBefore: 20
            },
            1600: {
                slidesOffsetBefore: process.browser && (window.innerWidth - 1600) / 2
            }
        },
        on: {
            slideChange: () => {
                if (isBrowser) {
                    if (gtmPDPInterior) {
                        window.dataLayer.push({
                            event: 'swipe',
                            eventCategory: 'PDPSlider',
                            eventAction: 'Interior'
                        })
                    }

                    if (gtmPDPExterior) {
                        window.dataLayer.push({
                            event: 'swipe',
                            eventCategory: 'PDPSlider',
                            eventAction: 'Exterior'
                        })
                    }
                }
            }
        }
    }

    const uid = useUIDSeed()

    return (
        <PhotoGallerySliderStyled
            specialOffers={specialOffers}
            featuredYachts={featuredYachts}
            largeOffset={largeOffset}>
            {uniqueImages.length > 1 ? (
                <>
                    <Box theme={layoutTheme} display={specialOffers ? ['none', null, 'block'] : 'block'}>
                        <Swiper {...params}>
                            {uniqueImages.map(item => (
                                <div key={uid(item)}>
                                    <div className="Inner">
                                        {item.boatURL && (
                                            <Link href={item.boatURL} as={item.boatURLas}>
                                                <a className="blockLink" />
                                            </Link>
                                        )}

                                        <div className="slideImage">
                                            <button type="button" onClick={e => openImageModal(item.url)}>
                                                <img src={item.url} alt="Merk&Merk" />
                                            </button>
                                        </div>

                                        {item.name && (
                                            <div className="slideContent">
                                                <p className="name">{item.name}</p>
                                                <ul className="specification">
                                                    {item.condition && <li>{item.condition} •</li>}

                                                    {item.year && <li>{item.year} •</li>}

                                                    {item.length && <li>{item.length}m</li>}
                                                </ul>
                                                <p className="price">
                                                    {item.currency} {item.price}
                                                    <span className="vat">
                                                        {item.vat === 'Yes' ? t('vat-yes') : t('vat-no')}
                                                    </span>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Swiper>
                    </Box>

                    <Box theme={layoutTheme} display={specialOffers ? ['block', null, 'none'] : 'none'} p={20}>
                        {uniqueImages.map((item, index) => (
                            <div
                                key={uid(item)}
                                className={index > 0 && !showMoreOffers ? 'hidden boatItem' : 'boatItem'}>
                                <div className="Inner">
                                    {item.boatURL && (
                                        <Link href={item.boatURL} as={item.boatURLas}>
                                            <a className="blockLink" />
                                        </Link>
                                    )}

                                    <div className="slideImage">
                                        <button type="button" onClick={e => openImageModal(item.url)}>
                                            <img src={item.url} alt="Merk&Merk" />
                                        </button>
                                    </div>

                                    {item.name && (
                                        <div className="slideContent">
                                            <p className="name">{item.name}</p>
                                            <p className="specification">
                                                {item.condition && (
                                                    <li>
                                                        {t('controls:condition')}: <strong>{item.condition}</strong> •
                                                    </li>
                                                )}

                                                {item.year && (
                                                    <li>
                                                        {t('controls:year')}: <strong>{item.year}</strong> •
                                                    </li>
                                                )}

                                                {item.length && (
                                                    <li>
                                                        {t('controls:length')}: <strong>{item.length}m</strong>
                                                    </li>
                                                )}
                                            </p>
                                            <p className="price">
                                                {item.currency} {item.price}
                                                <span className="vat">
                                                    {item.vat === 'Yes' ? t('vat-yes') : t('vat-no')}
                                                </span>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}

                        {!showMoreOffers && (
                            <Box theme="layoutTheme" textAlign="center" mt={20}>
                                <Button variation="secondary rounded" smallest onClick={() => handleShowMoreOffers()}>
                                    {featuredButton ? t('see-all-featured-yachts') : t('see-all-special-offers')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </>
            ) : (
                <div className="singularImage">
                    {uniqueImages.map(item => (
                        <img key={uid(item)} src={item.url} alt="Merk&Merk" />
                    ))}
                </div>
            )}

            {modalOpen && (
                <Modal close={() => setModalOpen(false)} bodyScroll={false}>
                    <QuickPinchZoom onUpdate={onUpdate}>
                        <img src={modalImageUrl} alt="Merk&Merk" ref={imgRef} />
                    </QuickPinchZoom>
                </Modal>
            )}
        </PhotoGallerySliderStyled>
    )
}

PhotoGallerySlider.propTypes = {
    t: PropTypes.func.isRequired,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            name: PropTypes.string,
            specification: PropTypes.string,
            price: PropTypes.string
        })
    ).isRequired,
    gtmPDPInterior: PropTypes.bool,
    gtmPDPExterior: PropTypes.bool,
    specialOffers: PropTypes.bool,
    featuredYachts: PropTypes.bool,
    featuredButton: PropTypes.bool
}

PhotoGallerySlider.defaultProps = {
    gtmPDPInterior: false,
    gtmPDPExterior: false,
    specialOffers: false,
    featuredYachts: false,
    featuredButton: false
}

export default withTranslation('common', 'controls')(withTheme(PhotoGallerySlider))
