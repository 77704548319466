import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, specialOffers, featuredYachts, largeOffset }) => ({
    position: 'relative',

    '& .Slider-Heading': {
        position: 'relative',
        maxWidth: toRem(1280),
        margin: 'auto'
    },

    '& .swiper-container': {
        paddingBottom: 60,

        [theme.breakpoints.up('lg')]: {
            paddingBottom: 0
        },

        '& .swiper-slide': {
            width: 'auto',
            paddingRight: toRem(15)
        },

        '& .Inner': {
            position: 'relative'
        },

        '& .slideImage': {
            img: {
                borderRadius: toRem(4),
                maxHeight: 200,
                maxWidth: 300,
                width: 'auto',

                [theme.breakpoints.up('480')]: {
                    maxWidth: 9999,
                    maxHeight: 240
                },

                [theme.breakpoints.up('md')]: {
                    maxWidth: 680,
                    maxHeight: 460
                },

                [theme.breakpoints.up('lg')]: {
                    maxWidth: 9999,
                    maxHeight: 640
                }
            }
        }
    },

    '& .imageModal': {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        background: theme.colors.blueMid,
        // padding: toRems([40, 0]),
        textAlign: 'center',

        '& .closeButton': {
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 2
        },

        img: {
            width: 'auto',
            height: 'auto',
            maxWidth: 99999
        }
    },

    '& .blockLink': {
        textIndent: -9999,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    },

    '& .slideContent': {
        marginTop: 20,

        [theme.breakpoints.up('md')]: {
            display: 'inline-block'
        },

        p: {
            fontSize: toRem(14)
        },

        '.name': {
            fontSize: toRem(19),
            fontWeight: 600,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(20)
            }
        },

        '.specification': {
            marginBottom: 10,
            paddingBottom: 10,
            maxWidth: featuredYachts ? 266 : 99999,
            listStyle: 'none',
            display: 'inline-flex',
            flexWrap: 'wrap',
            width: 'auto',

            li: {
                fontSize: toRem(14),
                marginRight: 3,

                '&:last-of-type': {
                    marginRight: 0
                }
            },

            [theme.breakpoints.up('480')]: {
                maxWidth: featuredYachts ? 320 : 9999
            },

            [theme.breakpoints.up('md')]: {
                maxWidth: 99999
            }
        },

        '.price': {
            color: theme.colors.goldDark,
            fontWeight: 200,

            [theme.breakpoints.up('md')]: {
                fontSize: toRem(18)
            },

            '.vat': {
                display: 'block',
                color: theme.colors.goldDark,
                textTransform: 'uppercase',
                fontSize: toRem(12),
                fontWeight: 400
            }
        }
    },

    '& .swiper-scrollbar': {
        display: 'none',
        position: 'relative',
        marginTop: 80,
        width: 'auto',
        maxWidth: specialOffers ? 1600 : 1280,
        left: 'auto',
        bottom: 10,
        height: 50,
        background: 'transparent',
        zIndex: '9',

        '&:before': {
            content: '""',
            height: 4,
            width: '100%',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            background: specialOffers ? null : theme.colors.blueMid
        },

        '&:hover': {
            cursor: 'pointer',

            '.swiper-scrollbar-drag': {
                height: 8,
                top: 21
            }
        },

        [theme.breakpoints.up('lg')]: {
            display: 'block',
            marginLeft: 40,
            marginRight: 40
        },

        [theme.breakpoints.up('xxl')]: {
            marginLeft: 'auto',
            marginRight: 'auto'
        },

        '& .swiper-scrollbar-drag': {
            background: theme.colors.goldDark,
            height: 4,
            transition: 'all 0.5s',
            top: 23
        }
    },

    '& .swiper-button-prev, & .swiper-button-next': {
        top: 'auto',
        bottom: 0,
        left: 'auto',
        width: 40,
        height: 40,
        background: theme.colors.goldDark,
        borderRadius: 4,
        backgroundImage: 'url(/icons/arrow-right.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '12px center',

        [theme.breakpoints.up('lg')]: {
            bottom: 60
        }
    },

    '& .swiper-button-prev': {
        left: 20,
        transform: 'rotate(180deg)',

        [theme.breakpoints.up('1600')]: {
            left: largeOffset
        }
    },

    '& .swiper-button-next': {
        left: 70,

        [theme.breakpoints.up('1600')]: {
            left: largeOffset + 50
        }
    },

    '.singularImage': {
        textAlign: 'center',
        padding: '0 20px',

        img: {
            borderRadius: 5
        }
    },

    // Special offers
    '.hidden': {
        display: 'none'
    },

    '& .boatItem': {
        position: 'relative',
        marginBottom: toRem(40)
    }
})

export default style
