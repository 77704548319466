import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    background: theme.colors.blueMid,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    img: {
        width: 'auto',
        height: 'auto',
        maxWidth: 99999
    },

    '& .closeButton': {
        position: 'fixed',
        top: 20,
        right: 20,
        zIndex: 2
    },

    '& .inner': {
        width: '100%'
    }
})

export default style
